import request from '@/utils/request'

export function login(data) {
    return request({
        url: '/login',
        method: 'post',
        data,
    })
}
export function register(data) {
    return request({
        url: '/dcCustomercenterNewcustomerreview/register',
        method: 'post',
        data,
    })
}

export function getUserInfo() {
    return request({
        url: '/userInfo',
        method: 'get',
    })
}
export function getQueryRegion() {
    return request({
        url: '/dcBasearchiveRegion/queryRegion',
        method: 'get',
    })
}

export function logout() {
    return request({
        url: '/logout',
        method: 'get',
    })
}
export function getSaveCustomerMsg(params) {
    return request({
        url: '/shoppingMallCommodity/saveCustomerMsg',
        method: 'get',
        params
    })
}

export function getRouterPermissions(data) {
    return request({
        url: '/sysRoleMenu/getMenuByRoleCode',
        method: 'post',
        data
    })
}



// 查询U8所有客户(只查客户名称和客户编码)
export function queryAll(params) {
    return request({
        url: '/customer/queryAll',
        method: 'post',
        params
    })
}